.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem 0 4rem;
    text-align: center;
    font-size: 0.875rem;
    background: $color-link;

    &-box {
        width: 100%;
        max-width: 32rem;
        padding: 1rem;
        margin: 0 auto;
        color: inherit;
        background: white;
        border-radius: 1.5rem;
    }

    &-step {
        min-height: 250px;
    }

    &-title {
        margin: 2rem 0;
        font-size: 1.75rem;
    }

    &-subtitle {
        margin-bottom: 3rem;
    }

    &-description {
    }

    &-form {

        &-fields {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
        }

        .form-control {
            width: 2.25em;
            min-width: 0;
            margin: 0 0.25rem;
            text-align: center;
            font-size: 1.5rem;
        }
    }

    &-note {
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: .625rem;
    }

    .btn-link {
        vertical-align: baseline;
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none;
        font-size: 1em;
        font-weight: inherit;
        border: none;
    }
}

.login-auth-code {

}