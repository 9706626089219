button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

.btn {
    border-radius: 100em;

    &-xs,
    &-group-xs > & {
        padding: 0.125rem 1rem;
        font-size: 0.875rem;
        line-height: 1.25;
        border-radius: 0.15rem;
    }

    &-link {
        padding-left: 0;
        padding-right: 0;
        font-weight: 700;
        text-decoration: underline;
    }

    &-round {
        border-radius: 2rem;
    }

    &-red {
        color: white;
        background: $color-red;

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-red, 0.5);
        }
    }

    &-red-dark {
        color: white;
        background: $color-red-dark;

        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($color-red-dark, 0.5);
        }
    }

    &-purple {
        color: white;
        background: $color-purple;

        &:hover {
            color: white;
            background: $color-purple;
            box-shadow: 0 0 0 0.2rem transparentize($color-purple, 0.5);
        }
    }

    &.radius-6 {
        border-radius: 6px;
    }
}