.logout {

    &-overlay {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
    }

    &-box {
        position: fixed;
        z-index: 1000;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 2rem;
        text-align: center;
        background: white;
        border-radius: 1rem;
    }

    &-buttons {
        display: inline-flex;
        justify-content: space-between;
        align-content: center;
        gap: 1rem;
        margin-top: 2rem;

        .btn {
            width: 8rem;
        }
    }
}