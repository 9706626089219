.uploader {
    padding-top: 5rem;

    &-input {
        display: flex;
        margin-top: 3rem;

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0;
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            border-radius: 100px;

            span {
                position: relative;
                top: -0.075em;
                font-size: 2.5rem;
            }
        }
    }

    &-box {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        min-height: 50vh;
        border: 1px solid $gray-500;

        &-dragging {
            background: $gray-100;
            border-style: dashed;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.5rem;

            .btn {
                padding: 0;
            }
        }

        &-title {
            margin: 0;
        }

        &-files {
            flex-grow: 1;

            > p {
                padding: 0 1.5rem;
            }

            table {
                width: 100%;
                font-size: 0.875rem;
                color: $gray-500;

                tr {

                    &:nth-child(odd) {
                        background: $gray-200;
                    }
                }

                td {
                    padding: 0.25rem 0.5rem;
                    color: black;

                    &:first-child {
                        width: 4em;
                        padding-left: 1.5rem;
                    }

                    &:nth-last-child(2),
                    &:last-child {
                        text-align: right;
                    }

                    &:last-child {
                        width: 4em;
                        padding-right: 1.5rem;
                    }

                    .msg {
                        font-weight: 400;
                        color: black;
                        font-size: 0.7rem;
                    }
                }

                h6 {
                    margin: 0;
                    font-weight: 400;
                    font-size: inherit;
                    color: black;

                    &.error {
                        color: $gray-500;
                    }
                }

                .btn {
                    width: 1.25rem;
                    height: 1.25rem;
                    padding: 0;
                    text-decoration: none;
                    color: white;
                    background: $gray-500;
                    border: none;
                    border-radius: 10em;

                    &.btn-success {
                        background: $success;
                        opacity: 1 !important;
                    }

                    span {
                        display: block;
                        height: 1.25rem;
                        line-height: 1.25rem;
                        font-size: 0.875rem;
                    }
                }
            }
        }

        &-submit {
            padding: 1.5rem;
            text-align: center;
            border-top: 1px solid $gray-500;

            &-message {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .left {
                display: flex;
                justify-content: stretch;
                align-items: center;
                text-align: left;
                margin-right: 1rem;
            }

            .right {
                text-align: left;
            }

            .badge {
                width: 2rem;
                height: 2rem;
                padding: 0;
                margin-right: 0.5rem;
                font-size: 1.5rem;
                line-height: 2rem;
                border-radius: 10em;

                &-white {
                    color: $color-link;
                }
            }

            h6 {
                margin-bottom: 0.25rem;
                font-size: 1.125rem;
                white-space: nowrap;
            }

            p {
                margin: 0;
                font-size: 0.625rem;
            }
        }
    }
}