.header {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;

    &-guest {
        color: white;
    }

    &-user {
        color: inherit;
    }

    &-brand {
        display: flex;
        align-items: center;
        padding: 0.25rem 0;
        color: inherit !important;

        @media (max-width: $breakpoint-md - 1) {
            font-size: 1rem;
        }
    }

    &-logo {
        margin-right: 1rem;

        img {
            display: block;
            height: 1rem;

            @media (min-width: $breakpoint-sm) {
                height: 2rem;
            }
            @media (min-width: $breakpoint-xl) {
                height: 3rem;
            }
        }
    }

    &-menu {
        display: flex;
        align-items: center;

        .btn {
            flex-shrink: 0;
            flex-grow: 0;

            &-primary {
                width: 3rem;
                height: 3rem;
                padding: 0;
                margin-left: 1rem;
                font-size: 1.25rem;
                font-weight: 700;
                color: white;
                background: $success !important;
                border: none;
                opacity: 1 !important;
            }

            &-link {
                padding: 0;
                font-weight: 400;
                color: inherit;
                text-decoration: none;
            }
        }
    }
}