.form {

    label {
        user-select: none;
    }

    .invalid-feedback {
        display: block;
    }

    &-control {
        width: 100%;
        border: 1px solid $gray-400;
        border-radius: 0.5rem;

        &-invalid {
            border-color: $color-red-dark !important;
        }
    }
}