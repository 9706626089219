.auth-base-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #fff;
    color: #000000;

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1.75rem;
        background-color: #eef0f0;
        box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    }

    &__header-logo {
        display: inline-block;
        width: 8.125rem;
        height: 6.5rem;
        background-image: url(../../../images/nixio_logo_color.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__header-text {
        display: inline-block;
        color: #4830d3;
        font-size: 1.375em;
        font-weight: 500;
        margin-left: 1.875rem;
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__footer {
        font-size: 1.125rem;
        font-weight: 400;
        text-align: center;
        margin-top: 6.25rem;
        margin-bottom: 5rem;
    }
}

.code-verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    &__title {
        font-size: 2.25rem;
        font-weight: 500;
    }

    &__description {
        margin-top: 3.75rem;
        font-size: 1.5rem;
        font-weight: 500;
    }

    &__info {
        margin-top: 0.625rem;
        font-size: 1.125rem;
    }

    &__code-input {
        margin-top: 3rem;
    }

    &__expire-info {
        margin-top: 2.8rem;
        font-size: 1rem;
    }

    &__resend {
        margin-top: 2.5rem;
        font-size: 1rem;

        &-btn {
            border: none;
            padding: 0;
            background: none;
            outline: none;
            box-shadow: none;
            text-decoration: none;
            font-weight: 500;
            color: #4830d3;
            margin-left: 0.5em;

            &--disabled {
                color: #ccc;
                cursor: not-allowed;
            }
        }
    }

    &__verify {
        margin-top: 5.625rem;

        &-btn {
            width: 9.375rem;
            background-color: #4830d3;
        }
    }
}

.auth-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    &__title {
        font-size: 2.25rem;
        font-weight: 500;
    }

    &__description {
        margin-top: 3.75rem;
        font-size: 1.5rem;
    }

    &__email {
        margin-top: 20px;
        max-width: 350px;
        width: 350px;
        font-size: 1rem;
    }

    &__confirmation {
        margin-top: 3.75rem;

        &-btn {
            width: 150px;
            background-color: #4830d3;
        }
    }
}

.auth-email-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    &__title {
        font-size: 2.25rem;
        font-weight: 500;
    }

    &__description {
        margin-top: 3.75rem;
        font-size: 1.5rem;
    }

    &__resend {
        margin-top: 5rem;
        font-size: 1.125rem;

        &-btn {
            border: none;
            padding: 0;
            background: none;
            outline: none;
            box-shadow: none;
            text-decoration: none;
            font-weight: 500;
            color: #4830d3;
            margin-left: 0.5em;

            &--disabled {
                color: #ccc;
                cursor: not-allowed;
            }
        }
    }
}

.auth-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    &__title {
        font-size: 2.25rem;
        font-weight: 500;
    }

    &__description {
        margin-top: 3.75rem;
        font-size: 1.5rem;
    }
}

.auth-new-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    &__title {
        font-size: 2.25rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }

    &__description {
        margin-top: 3.75rem;
        font-size: 1.5rem;
    }

    &__password {
        margin-top: 2.5rem;
        max-width: 350px;
        width: 350px;
        font-size: 1rem;
    }

    &__password-confirm {
        margin-top: 1.25rem;
        max-width: 350px;
        width: 350px;
        font-size: 1rem;
    }

    &__guideline {
        margin-top: 1.875rem;
        max-width: 460px;
        padding: 1.25rem;
        border-radius: 6px;
        background-color: #eef0f0;
    }

    &__guideline-title {
        font-size: 1.125rem;
        font-weight: 500;
    }

    &__guideline-description {
        margin-top: 1rem;
        font-size: 1rem;

        ul {
            padding-inline-start: 1.25rem;
        }
    }

    &__submit {
        margin-top: 5.625rem;

        &-btn {
            width: 9.375rem;
            background-color: #4830d3;
        }
    }

    &__result {
        text-align: center;
        font-weight: 500;
        font-size: 1.5rem;
    }

    &__result-info {
        text-align: center;
        font-size: 1.125rem;
    }
}