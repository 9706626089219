html, body, #root {
    height: 100%;
}

main {
    padding: 1rem 0;
}

.container,
.container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
}

.row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}